@use "allfonts";

//Theme Variables;
$font-spartan: Spartan;
$font-roboto-slab: "Roboto Slab", serif;
$font-inherit: inherit;
$text-s-semibold: Inter;
$font-plus-jakarta-sans: "Plus Jakarta Sans";
$text-m-semi-bold-size: 0.94rem;
$font-size-xs: 0.75rem;
$text-s-semibold-size: 0.88rem;
$font-size-xl-5: 1.28rem;
$font-size-base: 1rem;
$font-size-xl: 1.25rem;
$font-size-lg: 1.13rem;
$color-darkslateblue-100: #3e2d71;
$color-darkslateblue-200: #21153b;
$color-darkslateblue-300: #2e215573;
$color-darkslateblue-400: #21184100;
$color-darkslateblue-500: #2e2155ba;
$color-darkslateblue-600: #201c40;
$color-white: #fff;
$color-lavender-200: #dbd5e4d6;
$color-lavender-300: #dbd5e466;
$color-lavender-400: #dbd5e4ba;
$color-gray-100: #fdfeff;
$color-gray-400: #18112b99;
$color-gray-700: #fffc;
$color-gray-1000: #ffffff7a;
$color-silver: #bbb;
$color-turquoise: #03e1c336;
$color-gold: #ffcb29;
$color-thistle: #dfd4ff24;
$br-7xs: 6px;
$egg-blue: #04dec5;
$blue-gradient-2: linear-gradient(285deg, #34a189 16.56%, #4169b7 88.55%);
$blue-gradient-3: linear-gradient(276deg, #a96a14 4.48%, #1b80fe 68.02%);

@mixin blue-linear-gradient() {
    background: rgb(4, 222, 197);
    background: linear-gradient(
        90deg,
        rgba(4, 222, 197, 1) 0%,
        rgba(27, 128, 254, 1) 50%,
        rgba(3, 225, 195, 1) 100%
    );
}

// Bootstrap Conf

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
$enable-responsive-font-sizes: true; // Enabling Responsive Font Sizing
@import "../../../../node_modules/bootstrap/scss/mixins";
// @import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/reboot";
// @import "../../../../node_modules/bootstrap/scss/type";
// @import "../../../../node_modules/bootstrap/scss/images";
// @import "../../../../node_modules/bootstrap/scss/grid";
// @import "../../../../node_modules/bootstrap/scss/tables";
// @import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/buttons";
// @import "../../../../node_modules/bootstrap/scss/transitions";
// @import "../../../../node_modules/bootstrap/scss/dropdown";
// @import "../../../../node_modules/bootstrap/scss/button-group";
// @import "../../../../node_modules/bootstrap/scss/input-group";
// @import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
// @import "../../../../node_modules/bootstrap/scss/progress";
@import "../../../../node_modules/bootstrap/scss/media";
// @import "../../../../node_modules/bootstrap/scss/list-group";
@import "../../../../node_modules/bootstrap/scss/close";
@import "../../../../node_modules/bootstrap/scss/modal";
@import "../../../../node_modules/bootstrap/scss/utilities";

@import "../../../../node_modules/swiper/swiper";
@import "../../../../node_modules/swiper/modules/autoplay/autoplay.scss";
@import "../../../../node_modules/swiper/modules/controller/controller.scss";
@import "../../../../node_modules/swiper/modules/navigation/navigation.scss";
@import "../../../../node_modules/swiper/modules/pagination/pagination.scss";

// *{
//   font-size:12px;
// }

body:not(.light-mode) {
    background-color: $color-darkslateblue-200;
}

.bg-darktheme {
    background-color: #21153b;
}

// body {
//   background-color: $color-darkslateblue-200;
// }
// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Utilities

.font-spartan {
    font-family: $font-spartan;
    &.font-600 {
        font-weight: 600;
    }
}

.belt-button {
    font-size: 0.75rem;
    font-family: $font-roboto-slab;
    text-align: center;
    vertical-align: top;
    @include media-breakpoint-up(md) {
        &:not(.small-belt-button) {
            min-width: 8rem;
        }
    }

    &.border-belt-button {
        background: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
            left top/100% 100% no-repeat;
        color: white;
        @include media-breakpoint-up(md) {
            background: none;
            border-style: solid;
            border-width: 1px;
            // -moz-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
            //   25 40 16 17 / 40px 70px 25px 30px / 0px repeat repeat;
            // -webkit-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
            //   25 40 16 17 / 40px 70px 25px 30px / 0px repeat repeat;
            // -o-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
            //   25 40 16 17 / 40px 70px 25px 30px / 0px repeat repeat;
            // border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
            //   25 40 16 17 / 40px 70px 25px 30px / 0px repeat repeat;
            -moz-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
                50% 50% 50% 50% / 100% 100% 100% 100% / 0px repeat repeat;
            -webkit-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
                50% 50% 50% 50% / 100% 100% 100% 100% / 0px repeat repeat;
            -o-border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
                50% 50% 50% 50% / 100% 100% 100% 100% / 0px repeat repeat;
            border-image: url("https://cdn.spartanpoker.com/media/svg/belt-border-2.svg")
                50% 50% 50% 50% / 100% 100% 100% 100% / 0px repeat repeat;
        }
    }

    &.fill-belt-button {
        // border-style: solid;
        // border-width: 1px;
        // -moz-border-image: url('https://cdn.spartanpoker.com/media/svg/fill-button-2.svg') 25 40 17 17 / 40px 70px 25px 30px / 0px  repeat repeat;
        // -webkit-border-image: url('https://cdn.spartanpoker.com/media/svg/fill-button-2.svg') 25 40 17 17 / 40px 70px 25px 30px / 0px  repeat repeat;
        // -o-border-image: url('https://cdn.spartanpoker.com/media/svg/fill-button-2.svg') 25 40 17 17 / 40px 70px 25px 30px / 0px  repeat repeat;
        // border-image: url('https://cdn.spartanpoker.com/media/svg/fill-button-2.svg') 25 40 17 17 / 40px 70px 25px 30px / 0px  repeat repeat;
        color: black;
        position: relative;
        z-index: +1;
        background: url("https://cdn.spartanpoker.com/media/svg/fill-button-2.svg")
            left top/100% 100% no-repeat;

        &.small-belt-button {
            // border-image-source: url('https://cdn.spartanpoker.com/media/svg/small-fill-button.svg');
            // border-image-slice: 50%;
            // border-image-width: 50%;
            // border-image-outset:0px 0px 0px 0px;
            width: 3rem;
            height: 3rem;
            // border-image : url('https://cdn.spartanpoker.com/media/svg/small-fill-button.svg') 50% / 50% /0px;
            // border-width: 1px;
            // border-style: solid;
            background: url("https://cdn.spartanpoker.com/media/svg/small-fill-button.svg")
                center / auto no-repeat;
        }
        &.smaller-belt-button {
            background: url("https://cdn.spartanpoker.com/media/svg/smaller_button.svg")
                center/90% border-box border-box no-repeat;
            flex-basis: 5rem;
            height: auto;
            font-weight: bold;
            @include media-breakpoint-up(md) {
                width: 5rem;
                min-width: 5rem;
                height: auto;
            }
        }

        &:after {
            width: 100%;
            height: 100%;
            // background: url('https://cdn.spartanpoker.com/media/png/logo-transparent.png') 95%/contain no-repeat;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            opacity: 40%;
        }
    }

    p {
        pointer-events: none;
    }
}

.banner-sms-form-title {
    color: #d9d9d9;
    font-weight: 700;
    text-align: center;
}
.sms-form-error {
    position: relative;
}
.banner-sms-form .phone-input {
    background: rgb(4, 222, 197);
    background: linear-gradient(
        90deg,
        rgba(4, 222, 197, 1) 0%,
        rgba(27, 128, 254, 1) 50%,
        rgba(3, 225, 195, 1) 100%
    );
    padding: 0.1rem;
    border: none;
    position: relative;
    font-size: 1rem;

    input {
        background-color: $color-darkslateblue-100;
        border: none;
        color: white;
        font-family: $font-spartan;
        padding-left: 25%;
        padding-top: 2%;
        font-size: 1rem;

        &::placeholder {
            color: #d9d9d9;
            font-weight: 700;
            font-size: 0.9rem;
        }
    }

    &:before {
        content: "+91";
        position: absolute;
        font-family: Spartan;
        padding: 0.45rem 1rem;
        color: white;
        top: 0;
        left: 0;
        font-size: inherit;
        z-index: 1;
        height: 100%;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.5;
    }
    &:after {
        content: "";
        position: absolute;
        height: 50%;
        width: 1px;
        top: 50%;
        left: 20%;
        transform: translateY(-50%);
        background: white;
    }
}

.back-overlay {
    will-change: transform;
    // overflow: hidden;
    // position:absolute;
    // width:100%;
    // height: 100%;

    @include media-breakpoint-up(md) {
        &.eclipse-shape-blue {
            position: absolute;
            width: 493px;
            height: 492px;
            left: -171px;
            // top: 100%;
            // background: #0073D7;
            // opacity: 0.1;
            // filter: blur(10px);
            background: url("https://cdn.spartanpoker.com/images/eclipse_green.webp")
                center/cover no-repeat;
            pointer-events: none;
            border-radius: 50%;

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    top: (100% * ($i));
                }
            }
        }

        &.eclipse-shape-green {
            position: absolute;
            left: 76.25%;
            right: -19.1%;
            top: 4.95%;
            background: url("https://cdn.spartanpoker.com/images/eclipse_green.webp")
                center/cover no-repeat;
            // bottom: 78.89%;
            // background: $egg-blue;
            // opacity: 0.2;
            // filter: blur(150px);
            pointer-events: none;
            width: 493px;
            height: 492px;
            z-index: 1;
            border-radius: 50%;
        }
    }
}

.swiper-pagination {
    top: 105% !important;
    position: static;

    span.swiper-pagination-bullet {
        color: transparent;
        margin: 0 0.2rem;
        background-color: none;
        position: relative;
        background: #43307d;
        background-clip: padding-box;
        $border: 0.03rem;
        border: $border solid transparent;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        height: 10px;
        width: 10px;
        opacity: 1;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: linear-gradient(
                94.32deg,
                #a96a14,
                #e6a64d 26.87%,
                #ffe891 63.85%,
                #bb842a
            );
        }

        &.swiper-pagination-bullet-active {
            width: 1.5rem;
            border-radius: 1rem;
            background: linear-gradient(
                90deg,
                rgba(4, 222, 197, 1) 0%,
                rgba(27, 128, 254, 1) 50%,
                rgba(3, 225, 195, 1) 100%
            );
            &:before {
                background: rgb(4, 222, 197);
                background: linear-gradient(
                    90deg,
                    rgba(4, 222, 197, 1) 0%,
                    rgba(27, 128, 254, 1) 50%,
                    rgba(3, 225, 195, 1) 100%
                );
            }
        }
    }
}

// .fixed-md-top{
//   @include media-breakpoint-up(md){
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     z-index: 1030;
//   }
// }

.animated-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(27, 128, 254, 0.5);
    position: relative;
    border: 2px solid rgba(27, 128, 254, 1);

    i.fa.fa-play {
        pointer-events: none;
        background: linear-gradient(
            94.32deg,
            #a96a14,
            #e6a64d 26.87%,
            #ffe891 63.85%,
            #bb842a
        );
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
    }
}

.animated-btn::before,
.animated-btn::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background: rgba(12, 173, 241, 0.8);
    animation: ripple-1 2s infinite ease-in-out;
    z-index: -1;
    animation-play-state: paused;
}

.animated-btn::after {
    background: rgba(12, 173, 241, 0.6);
    animation: ripple-2 2s infinite ease-in-out;
    animation-delay: 0.5s;
    animation-play-state: paused;
}

.swiper-slide:hover .animated-btn::after {
    animation-play-state: running;
}
.swiper-slide:hover .animated-btn::before {
    animation-play-state: running;
}

@keyframes ripple-1 {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes ripple-2 {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.7);
        opacity: 0;
    }
}

.maintain-nav-align {
    @include media-breakpoint-up(md) {
        overflow: hidden;
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
}
.modal-backdrop.show {
    opacity: 0.9;
}

@include media-breakpoint-down(md) {
    .btn:focus,
    .btn.focus {
        box-shadow: none;
    }
}

// Utilities Ends

.social-left-bar {
    position: relative;

    &:before,
    &:after {
        content: "";
        @include blue-linear-gradient();
        width: 3rem;
        height: 2px;
        position: absolute;
        top: 50%;
    }

    &:before {
        left: 6%;
    }
    &:after {
        right: 6%;
    }

    @include media-breakpoint-up(md) {
        display: inline-block;
        position: absolute;
        height: auto;
        left: 1rem;
        top: 26%;
        z-index: 10;

        &:after,
        &:before {
            content: "";
            @include blue-linear-gradient();
            width: 0.1rem;
            height: 4rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        &:before {
            top: -5rem;
        }

        &:after {
            top: 110%;
            bottom: -5rem;
        }
    }
}

.blue-gradient-button {
    position: relative;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    $border: 0.1rem;
    border: $border solid transparent;
    border-radius: 65px 65px 65px 65px / 40px 40px 40px 40px;
    text-align: center;
    vertical-align: middle;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        border-radius: inherit;
        background: rgb(4, 222, 197);
        background: linear-gradient(
            90deg,
            rgba(4, 222, 197, 1) 0%,
            rgba(27, 128, 254, 1) 50%,
            rgba(3, 225, 195, 1) 100%
        );
    }

    &.social-square {
        width: 2rem;
        height: 2rem;
    }
}

header {
    .navbar {
        background-color: $color-darkslateblue-500;
        backdrop-filter: blur(2px);
        padding: 0;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            padding-left: 5.2rem;
            padding-right: 5.2rem;
        }
    }

    .nav-wrapper {
        font-size: 0.8rem;

        .nav-link {
            color: white !important;
            font-family: $font-spartan;
            line-height: 1.38rem;
            font-size: 0.9vw;
            svg {
                margin-right: 0.2rem;
            }
            &:hover,
            &.active {
                svg {
                    filter: drop-shadow(0px -5px 5px rgb(27, 128, 254));
                }
                path {
                    fill: url(#blue-grad);
                }
            }
        }

        flex: 0 1 40%;
    }

    background-color: $color-darkslateblue-500;

    .navbar-brand {
        flex: 1 0 auto;
        @include media-breakpoint-up(md) {
            flex: 0 1 10%;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    nav {
        @include media-breakpoint-up(lg) {
            & {
                // width:85%;
                margin: 0 auto;
            }
        }
    }

    .nav-init-buttons {
        display: flex;
        justify-content: space-between;
        flex: 1 0 100%;
        padding: 0.6rem;
        background-color: $color-darkslateblue-200;

        & .nav-init-link {
            flex: 0 1 40%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 1 20%;
            justify-content: flex-end;
            background-color: transparent;

            & .nav-init-link {
                flex: 0 1 auto;
            }
        }
    }

    .navbar-toggler-icon-2 {
        display: block;
        position: relative;
        width: 0.9em;
        height: 2px;
        background-color: $color-gray-700;
        transition: transform 250ms ease-in-out;
        top: 50%;
        border-radius: 1rem;

        &:after,
        &:before {
            content: "";
            height: 2px;
            background-color: $color-gray-700;
            position: absolute;
            right: 0;
            border-radius: 1rem;
        }

        &:after {
            width: 1.2em;
            top: 0.5rem;
        }

        &:before {
            width: 1.7em;
            bottom: 0.5rem;
        }
    }

    .navbar-toggler {
        .username {
            max-width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        flex: 0 1 auto;
    }

    .user-avatar {
        .username {
            color: white;
        }
    }

    .current-balance-wrapper {
        flex: 0 1 auto;
        .current-balance {
            font-size: 0.8vw;
            color: $color-lavender-400;
            font-family: $font-roboto-slab;
        }
        .current-balance-amount {
            font-size: 1vw;
            font-family: $font-spartan;
            font-weight: 900;
        }
    }
}

.user-icon {
    background: linear-gradient(180deg, #382364 0%, #4b3087 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#banner-platform-bar {
    max-width: 83%;
}
// .platform-icon-wrapper {
//   pointer-events: none;
// }

main {
    margin-top: 24%;
    @include media-breakpoint-down(md) {
        overflow: hidden;
    }
    @include media-breakpoint-up(md) {
        // margin-top:6rem;
        margin-top: 5rem;
    }
}

.main-right-bar {
    transition: transform 250ms ease-in-out z-index 0ms 250ms ease-in-out;
    // transform: translateX(100%);
    transform: translate3d(-100vw, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-darkslateblue-200;
    width: 100%;
    z-index: -1;

    &.page-loaded {
        transform: translate3d(100vw, 0, 0);
        z-index: 1100;
    }
    @include media-breakpoint-up(md) {
        width: 30rem;
    }

    &.show {
        transform: translate3d(0vw, 0, 0);
        // transform: translateX(0);
    }

    .main-right-bar-header {
        background-color: $color-darkslateblue-500;
        position: sticky;
        top: 0;
        backdrop-filter: blur(10px);
        z-index: +1;

        .header-back {
            // background: linear-gradient(180deg, #5C43A9 -25%, #2E2155 125%);
            // border: 1.21951px solid rgba(255, 255, 255, 0.04);
            // box-shadow: 0px 0px 12.1951px rgba(0, 0, 0, 0.1);
            background: linear-gradient(180deg, #382364 0%, #4b3087 100%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: inline-block;
        }

        .header-user-info {
            p {
                color: white;
                font-family: $font-spartan;
            }
        }
    }

    .main-right-bar-footer {
        &:before {
            content: "";
            width: 100%;
            margin: 1.5rem auto;
            display: block;
            height: 1px;
            background-color: rgba(27, 128, 254, 0.39);
        }
    }

    .main-activity-section {
        .activity-item {
            color: white;
            font-family: $font-spartan;
            a {
                color: inherit;
                font-family: inherit;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &:after {
            content: "";
            width: 100%;
            margin: 1.5rem auto;
            display: block;
            height: 1px;
            background-color: rgba(27, 128, 254, 0.39);
        }
    }

    .right-bar-footer-help-link {
        color: $color-lavender-400;
        font-family: $font-spartan;
    }

    .main-right-bar-title {
        letter-spacing: -0.02em;
        text-transform: capitalize;
        background: linear-gradient(180deg, #00efbb 0%, #1b80fe 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: $font-spartan;
        font-weight: 700;
    }
}

.sticky-right-bar {
    position: fixed;
    right: -18rem;
    top: 52%;
    z-index: 10;
    transform: rotate(270deg);

    .button-wrapper {
        width: 40rem;

        .button-inner-wrapper img {
            transform: rotate(90deg);
        }
    }
}

section#main-homepage-slider {
    $sliderHeight: 450px;
    $sliderWidth: 85%;
    $sliderRadius: 1.5rem;

    .slick-slider {
        width: $sliderWidth;
        height: $sliderHeight;
        margin: 0 auto;
        border-radius: $sliderRadius;
        // overflow: hidden;
    }

    .slick-slide,
    .slick-slide div,
    .slick-slide .image {
        height: $sliderHeight;
    }

    .slick-slide .image a {
        height: $sliderHeight;
        display: block;
    }

    .slick-slide .image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: $sliderRadius;
    }
}

// @include media-breakpoint-down(md){
//   [class^='slider-']{
//     overflow-x: hidden;
//   }
// }

.homepage-carousel-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
        @include media-breakpoint-down(md) {
            // width: 305px;
            width: 95%;
            margin-right: 20px;
        }
        height: auto;
        // overflow: hidden;
        background: linear-gradient(
                180deg,
                #2a8d87 0%,
                rgba(34, 117, 173, 0) 53.65%,
                #1d62ca 100%
            ),
            linear-gradient(152.52deg, #2d1e4d 6.88%, #301f61 93.33%);
        padding: 0.1rem;
        border-radius: 1.5rem;
        position: relative;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            border-radius: 3rem;
            height: 70vh;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: fill;
                border-radius: 3rem;
            }

            &:before {
                content: url("https://cdn.spartanpoker.com/media/svg/testimonial_attract.svg");
                width: auto;
                height: 50vh;
                // background: url("https://cdn.spartanpoker.com/media/svg/testimonial_attract.svg") top right/contain no-repeat;
                position: absolute;
                z-index: -1;
                bottom: -10%;
                left: -10%;
                pointer-events: none;
                object-fit: contain;
            }

            &:after {
                content: url("https://cdn.spartanpoker.com/media/svg/testimonial_attract.svg");
                width: auto;
                height: 50vh;
                // background: url("https://cdn.spartanpoker.com/media/svg/testimonial_attract.svg") top right/contain no-repeat;
                position: absolute;
                z-index: -1;
                top: -10%;
                right: -10%;
                pointer-events: none;
                object-fit: contain;
            }
        }
    }
    .swiper-pagination {
        position: absolute !important;
    }
}

.slick-slider {
    position: relative;
    .slick-dots {
        position: absolute;
        list-style-type: none;
        padding: 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        bottom: -12%;

        li {
            button {
                color: transparent;
                margin: 0 0.2rem;
                background-color: none;
                position: relative;
                background: #43307d;
                background-clip: padding-box;
                $border: 0.03rem;
                border: $border solid transparent;
                border-radius: 50%;
                text-align: center;
                vertical-align: middle;
                height: 10px;
                width: 10px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -$border;
                    border-radius: inherit;
                    background: linear-gradient(
                        94.32deg,
                        #a96a14,
                        #e6a64d 26.87%,
                        #ffe891 63.85%,
                        #bb842a
                    );
                }
            }

            &.slick-active button {
                width: 1.5rem;
                border-radius: 1rem;
                background: linear-gradient(
                    90deg,
                    rgba(4, 222, 197, 1) 0%,
                    rgba(27, 128, 254, 1) 50%,
                    rgba(3, 225, 195, 1) 100%
                );
                &:before {
                    background: rgb(4, 222, 197);
                    background: linear-gradient(
                        90deg,
                        rgba(4, 222, 197, 1) 0%,
                        rgba(27, 128, 254, 1) 50%,
                        rgba(3, 225, 195, 1) 100%
                    );
                }
            }
        }
    }
    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        color: transparent;
        z-index: +1;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: transparent;
        border: none;
        &:after {
            position: absolute;
            width: inherit;
            height: inherit;
            display: block;
            font-family: "Font Awesome 6 Free";
            font-weight: 400;
            color: white;
            top: 0;
            left: 0;
        }
    }
    .slick-prev {
        left: 0;
        &:after {
            content: "\f053";
        }
    }
    .slick-next {
        right: 0;
        &:after {
            content: "\f054";
        }
    }
}

.section-heading-wrapper {
    .section-heading {
        font-family: $font-spartan;
        letter-spacing: -0.02em;
        background: linear-gradient(
            94.32deg,
            #a96a14,
            #e6a64d 26.87%,
            #ffe891 63.85%,
            #bb842a
        );
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        line-height: 1.6rem;
        font-size: 1.45rem;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 0.3rem;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
            margin-bottom: 1rem;
            line-height: 2.5rem;
        }

        & + p.break-heading {
            line-height: 1.5rem;
            font-weight: 300;
            font-size: 1.45rem;
            @include media-breakpoint-up(md) {
                font-size: 2rem;
            }
        }
    }

    .section-heading-description {
        font-size: 0.9rem;
        font-family: $font-roboto-slab;
        color: $color-lavender-400;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            font-size: 0.9rem;
            width: 60%;
        }
    }

    h1.section-heading-2.h1 {
        font-size: 1rem;
        @include media-breakpoint-up(md) {
            font-size: $font-size-xl-5;
        }
    }

    .section-heading-2 {
        text-align: center;
        font-family: $font-spartan;
        color: $color-silver;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        line-height: 2.5rem;
        position: relative;
        font-size: $font-size-xl-5;
        font-weight: bold;
        display: inline-block;
        width: auto;

        &:after {
            content: "";
            width: 50%;
            height: 2px;
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            background: rgb(87, 63, 160);
            background: linear-gradient(
                180deg,
                rgba(87, 63, 160, 1) 1%,
                rgba(40, 27, 74, 1) 100%
            );
        }
    }
}
// Phone Bottom Navigation Bar
section#phone-nav {
    .navigation {
        height: 45px;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        // background: url("https://cdn.spartanpoker.com/media/svg/bottom-nav.svg") center/ 100% no-repeat;
        // background-color: $color-darkslateblue-600;
        // width:350px;
        width: 100%;
        // box-shadow: 0px -1px $color-gold;
        // box-shadow: 0px -.2px 1px $color-gold;

        .download-button-phone {
            background-image: url("https://cdn.spartanpoker.com/media/svg/bottom-nav.svg");
            background-repeat: no-repeat;
            background-size: 300px 100px;
            background-position: center -30px;
            .icon {
                width: 60px;
                top: -50%;
            }
            &:after {
                content: "";
                background-color: #160d28;
                height: 15px;
                position: absolute;
                bottom: 0;
                width: 100%;
                border-bottom: 1px solid #372d52;
            }
        }

        ul {
            display: flex;
            width: 100%;
            margin: 0;
            justify-content: space-around;

            li {
                position: relative;
                list-style: none;
                flex: 0 1 20%;
                height: 45px;
                &:not(.download-button-phone) {
                    background-color: #160d28;
                    // border-top: 1px solid #372d52;
                    // border-bottom: 1px solid #372d52;
                }
                &:first-child {
                    // border-top: 1px solid #372d52;
                    // border-bottom: 1px solid #372d52;
                    // border-top-left-radius: 50px;
                    // border-bottom-left-radius: 50px;
                }
                &:last-child {
                    // border-top-right-radius: 50px;
                    // border-bottom-right-radius: 50px;
                    // border-top: 1px solid #372d52;
                    // border-bottom: 1px solid #372d52;
                }
                a {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    .icon {
                        position: relative;
                        // display: block;
                        display: flex;
                        width: 45px;
                        height: 45px;
                        // background-color: red;
                        text-align: center;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                    }

                    &.active {
                        svg {
                            filter: drop-shadow(0px -5px 5px rgb(27, 128, 254));
                        }
                        path {
                            fill: url(#blue-grad);
                        }
                    }

                    @include media-breakpoint-up(md) {
                        &:hover,
                        &.active {
                            svg {
                                filter: drop-shadow(
                                    0px -5px 5px rgb(27, 128, 254)
                                );
                            }
                            path {
                                fill: url(#blue-grad);
                            }
                        }
                    }

                    .phone-nav-item-caption {
                        position: absolute;
                        display: inline-block;
                        text-align: center;
                        font-family: $font-spartan;
                        z-index: 4;
                        bottom: 0;
                        background: linear-gradient(
                            94.32deg,
                            #a96a14,
                            #e6a64d 26.87%,
                            #ffe891 63.85%,
                            #bb842a
                        );
                        background-clip: border-box;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                &.active a .icon {
                    // box-shadow:0px .5px 0px rgb(243,215,128);
                    // border: .5px solid rgba(169,106,20,.7);
                    // background: linear-gradient(225deg, rgba(243,215,128,1) 0%, rgba(169,106,20,1) 100%);
                    // transform: translateY(-40px);
                    z-index: 3;
                }
            }

            .indicator {
                position: absolute;
                width: 70px;
                height: 70px;
                background-color: $color-darkslateblue-600;
                top: -45px;
                border-radius: 50%;
                box-shadow:
                    0px 2px $color-darkslateblue-600,
                    0px -0.2px $color-gold;
                z-index: 2;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    height: 30px;
                    width: 30px;
                    background-color: transparent;
                    top: 16px;
                    left: -28px;
                    border-radius: 50%;
                    box-shadow: 15px 18px $color-darkslateblue-600;
                }
                &:after {
                    left: 96%;
                    right: -28px;
                    box-shadow: -15px 18px $color-darkslateblue-600;
                }

                &.indicator-2 {
                    position: absolute;
                    width: 70px;
                    height: 70px;
                    background-color: $color-darkslateblue-600;
                    top: -45px;
                    border-radius: 50%;
                    transition: 0.5;
                    // box-shadow: 0px -1px $color-gold;
                    z-index: 1;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        height: 32px;
                        width: 32px;
                        background-color: transparent;
                        top: 14px;
                        left: -30px;
                        border-radius: 50%;
                        box-shadow:
                            24px 15px $color-gold,
                            24px 15px $color-gold;
                        z-index: inherit;
                    }
                    &:after {
                        left: 96%;
                        right: -35px;
                        box-shadow:
                            -24px 15px $color-gold,
                            -24px 15px $color-gold;
                    }
                }
            }
        }
    }
}

.floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

.floating-chip {
    width: 10%;
    pointer-events: none;
    @include media-breakpoint-up(md) {
        width: 10%;
    }

    @for $i from 1 through 5 {
        &.floating:nth-child(#{$i}) {
            animation-delay: 800ms * $i;
        }
    }
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, -18px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

#curvy-svg,
#curvy-svg-2 {
    text {
        font-family: $font-spartan;
        font-size: 0.8rem;
        @include media-breakpoint-up(md) {
            font-size: 1.2rem;
        }
    }
}

.chat {
    position: fixed;
    top: 76%;
    left: 97%;
    transform: translate(-97%, -92%);
    z-index: 25;
    background-color: transparent;
    border: none;
    @include media-breakpoint-up(md) {
        top: 98%;
        left: 98%;
        transform: translate(-98%, -98%);
    }

    img {
        cursor: pointer;
        width: 50px;
        height: 70px;
        @include media-breakpoint-up(md) {
            width: 70px;
            height: 94px;
        }
    }
}

// THE POINT
@mixin gold-gradient-text() {
    font-family: $font-spartan;
    letter-spacing: -0.02em;
    background: linear-gradient(
        94.32deg,
        #a96a14,
        #e6a64d 26.87%,
        #ffe891 63.85%,
        #bb842a
    );
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.6rem;
}

.v2_download_now_wrapper {
    text-align: center;
    // flex: 0 1 80%;
    flex: 0 1 90%;
    text-decoration: none !important;
    // width: 80%;
    width: 90%;
}

.v2_download_now {
    width: 100%;
    font-family: $font-spartan;
    font-size: $font-size-lg;
    font-weight: 700;
    color: black;
    cursor: pointer;
    text-align: center;
    border: none;
    padding: 0.5rem 0.9rem;
    background-size: 200%;
    border-radius: 50px;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    animation: glit 0.7s ease-in-out infinite;
    pointer-events: none;

    span:nth-child(1) {
        margin-right: 5rem;
        margin-left: auto;
    }
    span:nth-child(3) {
        margin-right: auto;
        margin-left: 2rem;
    }

    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.v2_download_now:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.v2_download_now:focus {
    outline: none;
}

.v2_download_now.bn28 {
    background-image: linear-gradient(
        to right,
        #eb3941,
        #f15e64,
        #e14e53,
        #e2373f
    );
    box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}
@keyframes glit {
    from {
        background-position: 200% 0;
    }
    to {
        background-position: 0% 0;
    }
}
.v2_download_now.gold {
    background-image: linear-gradient(
        157deg,
        #a96a14 -34.28%,
        #e6a64d 5.23%,
        #ffe891 59.61%,
        #bb842a 112.75%
    );
}
.updown_animation {
    animation: updown 0.7s ease-in-out infinite;
}

@keyframes updown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10%); /* Adjust the distance as needed */
    }
}

.traits_2_0 {
    background: linear-gradient(
        180deg,
        #21153b 0%,
        #0d0818 25.02%,
        #0d0818 71.9%,
        #21153c 100%
    );
    .traits-row .single-traits {
        flex: 0 1 29%;
    }

    .trait_heading {
        @include gold-gradient-text();
        font-size: 0.9rem;
        text-align: center;
        font-weight: 700;
        margin: 0.3rem 0;
    }
}

// Blog template related
.news-page-wrapper {
    // color: #d9d9d9;
    leading-trim: both;
    text-edge: cap;
    font-family: $font-spartan;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 166.667% */

    .section-heading {
        text-transform: none;
    }

    .page-content {
        h3 {
            font-size: 1.45rem;
        }
        h4 {
            font-size: 1.25rem;
        }
        p,
        ul,
        li {
            line-height: 1.5rem;
            font-size: 0.8rem;
        }
        a {
            background: $blue-gradient-2;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-decoration: underline;
            font-weight: 700;
        }
    }
}

.news-page-image-wrapper {
    background: $blue-gradient-2;
    padding: 0.1rem;
    margin: 1rem 0;
    border-radius: 1.25rem;
    img {
        border-radius: 1.25rem;
        width: 100%;
        height: auto;
    }
}

.news-page-social-news-wrapper {
    .social-news {
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        border-image: $blue-gradient-3;
        border-image-slice: 1;
        ul {
            max-width: 15rem;
        }
    }
}

.article-item {
    // background: url(https://cdn.spartanpoker.com/media/svg/main_grain.webp) 100%/10%
    //         repeat,
    //     linear-gradient(
    //         120deg,
    //         #271c46,
    //         rgba(123, 100, 193, 1) 53%,
    //         rgba(54, 39, 98, 1) 87%
    //     );
    // border: 1.2px solid rgba(86, 70, 146, 0.9);
    border: 2px solid #cfe8ff;
    border-radius: 0.9rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .article-excerpt {
        font-weight: 200;
        color: #545454;
        font-size: 75%;
    }
    .article-item-img {
        width: 100%;
        // height: 10rem;
        height: 6rem;
        max-height: 6rem;
        object-fit: cover;
    }
    .article-body {
        display: flex;
        flex-direction: column;
        align-items: start;
        height: 100%;

        .generic-btn-1 {
            display: inline;
            margin-top: auto;
        }
    }
}

.generic-btn-1 {
    border: 1.5px solid transparent;
    border-radius: 30px;
    font-family: $font-spartan;
    font-size: 0.8rem;
    color: #fff;
    min-width: 7.8rem;
    display: inline-block;
    padding: 0.6rem 0rem;
    background:
        linear-gradient(180deg, #6a38d6 0%, #492f83 100%) padding-box,
        linear-gradient(to left, #a96a14, #1b80fe) border-box !important;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    &:hover {
        color: white;
        text-decoration: none;
    }
}

.maintain-nav-align-2 {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    @include media-breakpoint-up(md) {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
}

.breadcrumbs-section-2 {
    ol {
        margin-top: 1rem;
        margin-left: 0;
        display: flex;
        justify-content: end !important;
    }

    ol li {
        flex: 0 1 auto;
        margin-right: 0.3rem;
        background: $blue-gradient-2;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        a p {
            display: inline;
        }

        &:first-child {
            font-weight: bold;
        }

        &:not(:last-child)a:after {
            content: "/";
            font-weight: normal;
            color: black !important;
        }
    }
}

.rhs-right-bar {
    height: 100%;
    @include media-breakpoint-up(md) {
        & > div {
            position: sticky;
            top: 10%;
        }
    }
}

.page-top-header {
    display: flex;
    direction: column;
    align-items: center;
    justify-content: center;
    background: url("https://cdn.spartanpoker.com/media/png/blog_heading_bg_mob.webp")
        center/cover no-repeat;
    min-height: 10vh;
    height: 10vh;
    @include media-breakpoint-up(md) {
        background: url("https://cdn.spartanpoker.com/media/png/blog_heading_bg_desk.webp")
            center/cover no-repeat;
        min-height: 20vh;
        height: 20vh;
    }
}

// Blog template related ends
